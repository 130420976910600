

var desktopsize = 992;
var homeurl = 'dynanet.x02.ch';

document.addEventListener('DOMContentLoaded', function () {


// ScrollReveal for regular elements
ScrollReveal().reveal('.animate', {
  distance: '50px',
  duration: 800,
  origin: 'left',
  easing: 'ease-in-out',
  opacity: 0,
});
ScrollReveal().reveal('.animate-right', {
  distance: '50px',
  duration: 800,
  origin: 'right',
  easing: 'ease-in-out',
  opacity: 0,
});

// ScrollReveal for elements with ::before pseudo-elements
ScrollReveal().reveal('.animate-before', {
  distance: '50px',
  duration: 20,
  easing: 'ease-in-out',
  opacity: 1,
  afterReveal: function (el) {
    // Add a class to trigger ::before animation
    el.classList.add('is-visible');
  }
});

ScrollReveal().reveal('.animate-line', {

  duration: 20,  
  opacity: 1,
  beforeReveal: function (el) {
    el.style.width = '100%';
  },
  beforeReset: function (el) {
    el.style.width = '0';
  }
});


  if(jQuery('#share-button').length) {
document.getElementById('share-button').addEventListener('click', function() {
  if (navigator.share) {
    navigator.share({
      title: document.title,
      text: 'Interessanter Beitrag auf Dynanet.ch',
      url: window.location.href
    })
    .then(() => console.log('Danke für das Teilen!'))
    .catch(console.error);
    } else {
      // Fallback for browsers that don't support navigator.share
      const emailLink = `mailto:?subject=${encodeURIComponent(document.title)}&body=${encodeURIComponent(window.location.href)}`;
      window.location.href = emailLink;
    }
  });
  }
  // if there is a .glider, initialize it
  if(jQuery('.slick-slider').length) {

$(document).ready(function(){
  $('.slick-slider').slick({
    infinite: true,  // Enables infinite scrolling
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    arrows: true, // Show arrows for navigation
    prevArrow: '<button class="slick-prev red-nav-circle"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10.2 17.1"><path id="Rectangle_9" fill="none" stroke="#fff" stroke-width="2" d="M9.3.8L1.6,8.5l7.7,7.7"/></svg></button>',
    nextArrow: '<button class="slick-next red-nav-circle"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10.2 17.1"><path id="Rectangle_9" fill="none" stroke="#fff" stroke-width="2" d="M9.3.8L1.6,8.5l7.7,7.7"/></svg></button>',
    dots: true  // Enable dots navigation
  });
});

  lightbox.option({
    'resizeDuration': 100,
    'wrapAround': true,
    'disableScrolling': true,
    'showImageNumberLabel': false,
  });
  
}
});

  // .overlaypanel should follow the mouse:
  $( document ).on( 'mousemove', function( e ) {
    $( '.overlaypanel' ).css({
      'top': e.pageY + 20,
      'left': e.pageX + 20
    });});

jQuery( document ).ready( function( $ ) {


      


      
      $('.wpforms-field:not(.wpforms-field-layout)').each(function() {
        const inputField = $(this).find('input, textarea');
        const labelField = $(this).find('label');

        inputField.on('focus blur input', function() {
          if ($(this).val().length > 0 || $(this).is(':focus')) {
            labelField.addClass('label-visible');
            inputField.addClass('label-visible');
          } else {
            labelField.removeClass('label-visible');
            inputField.removeClass('label-visible');
          }
        });
      });

      if ($(window).width() >= desktopsize) {
      // on click below the header (below 492px from the top), close the menu
      /*
      $( document ).on( 'click', function( e ) {
        if( e.clientY > 492 ) {
          closeAllDesktop();
        }
      });
      */
     // if the mouse leaves the area below 492px from the top, close the menu
     let timer = null;

     $(document).on('mousemove', function(e) {
       if (!timer) {
         timer = setTimeout(function() {
           if (e.clientY > 492) {
             closeAllDesktop();
           }
           timer = null; // Reset the timer after execution
         }, 100);
       }
     });
     
      
      }


      // copy li.menukontakt to .spaceforcontact
      $( '.spaceforkontakt' ).html( $( 'li.menukontakt' ).html() );

      $( '#suchfeld, #suchfeldzwei' ).on( 'keydown keypress', function( e ) {
        if( e.which === 13 ) { // Check for Enter key
          e.preventDefault(); // Prevent form submission or other default behavior
          var suchwert = $( this ).val();
          window.location.href = 'https://' + homeurl + '/?s=' + suchwert;
        }
      });
      
      $( 'img.input-icon' ).on( 'click', function() {
          var suchwert = $( this ).prev().val();
          window.location.href = 'https://' + homeurl + '/?s=' + suchwert;
      });


      // make sure the header#header is loaded before we calculate the height

      function setHeaderHeight() {
        var headerheight = $( 'header.header' ).outerHeight();
        // if .header-normal doesn't have the class .is404:
        if( !$( '.header-normal' ).hasClass( 'is404' ) ) {
          // add the height of the header to the top margin of .header-normal
          $( '.header-normal' ).css( 'margin-top', headerheight );
        } else {

          $( '.header-normal.is404' ).css( 'margin-top', 0 );
        }

      }

      setHeaderHeight();

      $( window ).on( 'resize', function() {
        setHeaderHeight();
        closeMobile();
      });


      // on #openmobile click, toggle #nav-main > ul
        $( '#openmobile' ).on( 'click', function(e) {
          if( $( '#nav-main > ul' ).hasClass( 'open' ) ) {
          
            e.preventDefault();
            closeMobile();
      
          } else {
            
          e.preventDefault();
            closeSearchMobile();
            openMobile();


      
          }
        }
        );

        function closeMobile() {

          $( '#nav-main > ul' ).removeClass('open');
          $( 'body' ).css( 'overflow-y', 'auto' );
          // only adjust overflow-y of html
          $( 'html' ).css( 'overflow-y', 'auto' );
          $ ('#openmobile').removeClass('open');
          $ ('header').removeClass('open');

          $( '.spaceforkontakt' ).removeClass( 'open' );
          $( '#openmobile').html('<svg width="44" height="44" viewBox="0 0 44 44"><g><g id="Layer_1"><line class="cls-1" x1="7" y1="13" x2="37" y2="13" stroke-width="2px" /><line class="cls-1" x1="7" y1="22" x2="37" y2="22" stroke-width="2px" /><line class="cls-1" x1="7" y1="31" x2="37" y2="31" stroke-width="2px" /></g></g></svg>');
          $( '#nav-top > ul ' ).append( $('#nav-main > ul > li.navtop') );

      


        }

        function openMobile() {


          // if a menu item has the class .current-menu-parent, open it
          $( '#nav-main > ul > li' ).each( function() {
            if( $( this ).hasClass( 'current-menu-parent' ) ) {

              $( this ).find( '.megamenusubmenu' ).show();
              $( this ).addClass( 'open' );


            }
          });


          $( '.spaceforkontakt' ).addClass( 'open' );
          $( '#nav-main > ul' ).addClass('open');
          $( 'body' ).css( 'overflow-y', 'hidden' );
          $( 'html' ).css( 'overflow-y', 'hidden' );
          $ ('#openmobile').addClass('open');
          $ ('header').addClass('open');
          $( '#openmobile').html('<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 361"><circle id="Ellipse 22" cx="22" cy="22" r="21.5" fill="#FF4162"/><g id="Group 356"><line id="Line 57" x1="29.3809" y1="15.0802" x2="15.0809" y2="29.3802" stroke="white" stroke-width="2"/><line id="Line 58" x1="28.9198" y1="29.379" x2="14.6198" y2="15.079" stroke="white" stroke-width="2"/></g></g></svg>');
          // move all li elements from nav-top inside #nav-main > ul, but before li.menucontact (which is the last one)
          $( '#nav-main > ul' ).append( $( '#nav-top > ul > li.navtop' ) );

        }
        

      // on nav#nav-main click on top level .menu-item.menu-item-has-children, toggle the sub-menu:
        $ ('#nav-main .topmenu.menu-item.menusuche > a').on('click', function(e) {

          e.preventDefault();

          if( $( this ).parent().hasClass( 'open' ) ) {

            closeAllDesktop();


          } else {

          
            closeAllDesktop();
          $('header').addClass('addbg');
            $( this ).parent().find( '.megamenusubmenu' ).show();
            $( this ).parent().addClass( 'open' );
            $('h1.mainh').addClass('addmarginh');
            $( '#nav-main .menu-item.menusuche > a' ).html( '<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 227"><circle id="Ellipse 18" cx="25" cy="25" r="25" fill="#FF4162"/><g id="Group 226"><line id="Line 48" x1="17.9296" y1="32.0722" x2="32.0717" y2="17.9301" stroke="white" stroke-width="2"/><line id="Line 49" x1="17.9298" y1="17.9296" x2="32.0719" y2="32.0717" stroke="white" stroke-width="2"/></g></g></svg>' );
            $( '#suchfeld' ).focus();
          }

        });


        function closeAllDesktop($hideall = false) {


          if($(window).width() < desktopsize) {
            
            if(!$hideall) {
            $( ':not(.current-menu-parent) > .megamenusubmenu' ).hide();
            } else {
              $( '.megamenusubmenu' ).hide();
            }

          } else {  
            $( '.megamenusubmenu' ).hide();

          }
          $( '#nav-main .menu-item.menu-item-has-children' ).removeClass( 'open' );
          $( '#nav-main .menu-item.menusuche' ).removeClass( 'open' );
          $('header').removeClass('addbg');
          $('h1.mainh').removeClass('addmarginh');
          $( '#nav-main .menu-item.menusuche > a' ).html( '<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7" stroke="white" stroke-width="2"/><line x1="13.7071" y1="13.2929" x2="20.7071" y2="20.2929" stroke="white" stroke-width="2"/></svg>' );

        }
        if ($(window).width() >= desktopsize) {
              $( '#nav-main .topmenu.menu-item:not(.menu-item-has-children) > a' ).on( 'mouseenter', function(e) {
                if(!$(this).parent().hasClass('menusuche') && !$(this).parent().hasClass('menukontakt')) {

                  closeAllDesktop();
                }
              });


              $( '#nav-main .topmenu.menu-item.menu-item-has-children > a' ).on( 'mouseenter', function(e) {

                e.preventDefault();


                if( $( this ).parent().hasClass( 'open' ) ) {

                } else {

                
                  closeAllDesktop();
                if(!$(this).parent().hasClass('menusuche')){
                $('header').addClass('addbg');
                  $( this ).parent().find( '.megamenusubmenu' ).show();
                  $( this ).parent().addClass( 'open' );
                  $('h1.mainh').addClass('addmarginh');

                  }
                }
              });
              $( '#nav-main .topmenu.menu-item.menu-item-has-children > a' ).on( 'click', function(e) {
                e.preventDefault();
              });
          
        } else {

          $( '#nav-main .topmenu.menu-item.menu-item-has-children > a, #nav-main .topmenu.menu-item.menusuche > a' ).on( 'click', function(e) {

            e.preventDefault();
      
      
            if( $( this ).parent().hasClass( 'open' ) ) {

              closeAllDesktop(true);
              if($(this).parent().hasClass('current-menu-parent')) {
                $( this ).parent().find( '> .megamenusubmenu' ).hide();
                $( this ).parent().removeClass( 'open' );
              }
              
              
            } else {
            
              closeAllDesktop(true);
            $('header').addClass('addbg');
              $( this ).parent().find( '> .megamenusubmenu' ).show();
              $( this ).parent().addClass( 'open' );
              $('h1.mainh').addClass('addmarginh');

            }
          });

        }



        function closeSearchMobile() {
          $( 'body' ).css( 'overflow-y', 'auto' );
          $( 'html' ).css( 'overflow-y', 'auto' );
            $( '.megamenuspaceformobile' ).removeClass( 'filled' );
            $ ('header').removeClass('open');
            if($('.megamenuspaceformobile').html().length) {
            $( '#nav-main .menu-item.menusuche .megamenusubmenu' ).html($('.megamenuspaceformobile').html()) ;
            $('.megamenuspaceformobile').html('');          
            }

            $( 'a#searchmobile' ).html( '<svg version="1.1" fill="none" width="44" height="44" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><circle stroke-width="2px" class="st0" cx="18.8" cy="20.1" r="7"/><line class="st0" stroke-width="2px" x1="24.5" y1="25.4" x2="31.5" y2="32.4"/></svg>' );
        }
        function openSearchMobile() {
          $( 'body' ).css( 'overflow-y', 'hidden' );
          $( 'html' ).css( 'overflow-y', 'hidden' );
          $( '.megamenuspaceformobile' ).addClass( 'filled' );
          // move #nav-main "li.menusuche .megamenusubmenu".html() to .megamenuspaceformobile

            $ ('header').addClass('open');

          if($('#nav-main .menu-item.menusuche .megamenusubmenu').html().length) {
          $( '.megamenuspaceformobile' ).html($('#nav-main .menu-item.menusuche .megamenusubmenu').html()) ;
          $('#nav-main .menu-item.menusuche .megamenusubmenu').html('');
          }
          $( 'a#searchmobile' ).html( '<svg width="44" height="44" class="nocirc" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Group 361"><circle id="Ellipse 22" cx="22" cy="22" r="21.5" fill="#FF4162"></circle><g id="Group 356"><line id="Line 57" x1="29.3809" y1="15.0802" x2="15.0809" y2="29.3802" stroke="white" stroke-width="2"></line><line id="Line 58" x1="28.9198" y1="29.379" x2="14.6198" y2="15.079" stroke="white" stroke-width="2"></line></g></g></svg>' );
          $( '#suchfeld' ).focus();
          $( 'img.input-icon' ).on( 'click', function() {
            var suchwert = $( this ).prev().val();
            window.location.href = 'https://' + homeurl + '/?s=' + suchwert;
        });
      

        }

        // on #searchmobile click, get the html content of li.menusuche and append it after .navwrap > .wrapper 
        $( '#searchmobile' ).on( 'click', function(e) {
          e.preventDefault();




          if( $( '.megamenuspaceformobile' ).hasClass( 'filled' ) ) { 

              closeSearchMobile();

          } else {

            closeMobile();
            openSearchMobile();

          }
          $( '#suchfeld, #suchfeldzwei' ).on( 'keydown keypress', function( e ) {
            if( e.which === 13 ) { // Check for Enter key
              e.preventDefault(); // Prevent form submission or other default behavior
              var suchwert = $( this ).val();
              window.location.href = 'https://' + homeurl + '/?s=' + suchwert;

            }
          });
          
        });

        var transparentImg = '/wp-content/themes/dynanet-2024/static/images/transparent.png'; // Set the path to your transparent image

        // on .wrapleft a hover, get the data-image attribute and set it as the background image of .wrapright
        $( '.wrapleft .menu-item a' ).on( 'mouseenter', function() {
          var img = $( this ).data( 'bgimg' );
          
          $( '.wrapright img' ).attr( 'src', img );

        });


        $( '.wrapleft .menu-item a' ).on( 'mouseleave', function() {
          $( '.wrapright img' ).attr( 'src', transparentImg );
        });


        $(window).scroll(function() {
            if ($(this).scrollTop() >($(window).height() + 150)) {
                $('header').addClass('sticky');
            } else {
                $('header').removeClass('sticky');
            }


        });



          function isElementInView(element) {
            var elementTop = $(element).offset().top;
            var elementBottom = elementTop + $(element).outerHeight();
            
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
        
            return elementBottom > viewportTop && elementTop < viewportBottom;
          }
        
          function animateCounter(element) {
            var $this = $(element),
                countTo = $this.attr('data-count');
        
            $({ countNum: 0 }).animate({
              countNum: countTo
            }, {
              duration: 2000,
              easing: 'swing',
              step: function() {
                $this.text(Math.floor(this.countNum));
              },
              complete: function() {
                $this.text(this.countNum);
              }
            });
          }
        
          function checkCounters() {
            $('.counter-number').each(function() {
              var $this = $(this);
        
              // If the element is in view and hasn't been animated yet
              if (isElementInView($this) && !$this.hasClass('counted')) {
                $this.addClass('counted');
                animateCounter($this);
              }
            });
          }
        
          // Initial check in case some counters are already in view
          checkCounters();
        
          // Check again on scroll
          $(window).on('scroll', function() {
            checkCounters();
          });
        


  
  });


  var lastScrollTop = 0;

$(window).on('scroll', function() {
    var currentScrollTop = $(this).scrollTop();

    if (Math.abs(currentScrollTop - lastScrollTop) > 100) {
        // Close the menu
        $( '.megamenusubmenu' ).hide();
        $( '#nav-main .menu-item.menu-item-has-children' ).removeClass( 'open' );
        $( '#nav-main .menu-item.menusuche' ).removeClass( 'open' );
        $('header').removeClass('addbg');
        $('h1.mainh').removeClass('addmarginh');
        $( '#nav-main .menu-item.menusuche > a' ).html( '<svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7" stroke="white" stroke-width="2"/><line x1="13.7071" y1="13.2929" x2="20.7071" y2="20.2929" stroke="white" stroke-width="2"/></svg>' );

    }

    lastScrollTop = currentScrollTop;
});